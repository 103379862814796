/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Location } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router'
import { filter, lastValueFrom } from 'rxjs'
import { take } from 'rxjs/operators'
import { ConfigService } from '../config/config.service'
import { WINDOW, WindowWrapper } from '../utils'
import { CookieConsentImplementationService } from '../cookie-consent/cookie-consent.service'
import { LocaleRetrieveUrlParamsSignupService } from '../config/locale-retrieve-url-params-signup.service'
import { LocaleHandlingService } from '../locale-handling/locale-handling.service'

@Injectable({
  providedIn: 'root',
})
export class SetupGuardService {
  constructor(
    private configService: ConfigService,
    private localeRetrieveUrlParamsService: LocaleRetrieveUrlParamsSignupService,
    @Inject(WINDOW) private window: WindowWrapper,
    private location: Location,
    private router: Router,
    private cookieConsentBackendService: CookieConsentImplementationService,
    private localeHandlingService: LocaleHandlingService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const params = this.localeRetrieveUrlParamsService.retrieve(route)
    this.configService.setUrlLocaleCountryInformation(params.locale, params.country)

    await lastValueFrom(this.configService.init(params.locale, params.country))
    const localeHandlingSuccess = await this.localeHandlingService.setup()

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(async () => {
        await this.cookieConsentBackendService.init(params.country)
      })

    return localeHandlingSuccess
  }
}
